import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination } from "@mui/material"
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions"
import { KonsilReport } from "../../data_access/entities/konsil_report";
import CheckIcon from '@mui/icons-material/Check';

interface IProps {
    konsilReports: KonsilReport[];
    handleTableRowClick: (id: string) => void;
    // handleDeleteButton: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => void;
}
export const KonsilReportPanel = (props: IProps) => {
    return (
        <TableContainer className="dashboard-table">
            {/* <Box className="mobile-table-header">
                <TableSort />
                <IconButton aria-label="delete">
                    <SortByAlpha />
                </IconButton>
            </Box> */}
            <Table stickyHeader aria-label="sticky table">
                <TableHead>
                    <TableRow>
                        <TableCell>LANR Auftraggeber</TableCell>
                        <TableCell>LANR Konsiliar</TableCell>
                        {/* <TableCell>Status des Konsils</TableCell> */}
                        <TableCell>Beauftragt</TableCell>
                        <TableCell>Rückfrage gestellt</TableCell>
                        <TableCell>Rückfrage beantwortet</TableCell>
                        <TableCell>Befundet</TableCell>
                        <TableCell>Befund abgerufen</TableCell>
                        {/* <TableCell>Wann abgeschlossen</TableCell> */}
                        <TableCell>Abgeschlossen</TableCell>
                        {/* <SortTableComponent activateCell={this.setPropertyNameAndDirection} active={this.state.propertyName === "patient"} propertyName="konsilPatient.vorname" title="Patient " />
                        <SortTableComponent activateCell={this.setPropertyNameAndDirection} active={this.state.propertyName === "konsilID"} propertyName="id" title="Konsil ID " />
                        <SortTableComponent activateCell={this.setPropertyNameAndDirection} active={this.state.propertyName === "zeitpunkt"} propertyName="datecreated" title="Zeitpunkt " />
                        <SortTableComponent activateCell={this.setPropertyNameAndDirection} active={this.state.propertyName === "konsilStatus"} propertyName="konsilStatus" title="Status " />
                        <SortTableComponent activateCell={this.setPropertyNameAndDirection} active={this.state.propertyName === "adressat"} propertyName="beauftragender_arzt.nachname" title="Adressat " /> */}
                        <TableCell align="right"></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.konsilReports.map(rowData => {                         
                        return (
                        <TableRow
                            className={"table-row"}
                            key={rowData.id}
                            onClick = {() => props.handleTableRowClick(rowData.id || "")}
                            //onClick={(event) => this.konsil(rowData.id)} //style={rowData.konsil.statusAenderung? {backgroundColor: '#e7d1d1'}: {}}
                        >
                            <TableCell className="konsil-id" scope="row">
                                {rowData.lanrHA || "unbekannt"}
                            </TableCell>
                            <TableCell className="konsil-id" scope="row">
                                {/* {`${rowData.lanrDerma || ""} / ${rowData.bsnrDerma || ""}`} */}
                                {rowData.lanrDerma || "unbekannt"}
                            </TableCell>
                            {/* <TableCell className="konsil-date">
                                {rowData.konsilStatus ? rowData.konsilStatus : "unbekannt"}
                            </TableCell> */}
                            <TableCell className="konsil-id" scope="row">
                                {rowData.dateBeauftragt ? new Date(rowData.dateBeauftragt).toLocaleDateString() : ""}
                            </TableCell>
                            <TableCell className="konsil-id" scope="row">
                                {rowData.dateRueckfrage ? new Date(rowData.dateRueckfrage).toLocaleDateString() : ""}
                            </TableCell>
                            <TableCell className="konsil-id" scope="row">
                                {rowData.dateRueckfrageBeantwortet ? new Date(rowData.dateRueckfrageBeantwortet).toLocaleDateString() : ""}
                            </TableCell>
                            <TableCell className="konsil-id" scope="row">
                                {rowData.dateBefundet ? new Date(rowData.dateBefundet).toLocaleDateString() : ""}
                            </TableCell>
                            <TableCell className="konsil-id" scope="row">
                                {rowData.dateAbrufBefund ? new Date(rowData.dateAbrufBefund).toLocaleDateString() : ""}
                            </TableCell>
                            {/* <TableCell className="konsil-id" scope="row">
                                {rowData.dateAbgeschlossen ? new Date(rowData.dateAbgeschlossen).toLocaleDateString() : ""}
                            </TableCell> */}
                            <TableCell className="konsil-id" scope="row">
                                {rowData.finished ? <CheckIcon/> : ""}
                            </TableCell>
                            <TableCell align="right"></TableCell>                         
                        </TableRow>)}
                    )}
                    {/* <TableRow className="table-footer-pagination">
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            colSpan={9}
                            count={50}
                            rowsPerPage={10}
                            page={1}
                            onPageChange={() => console.log("error")}
                            onRowsPerPageChange={() => console.log("error")}
                            ActionsComponent={TablePaginationActions}
                        />
                    </TableRow> */}
                </TableBody>
            </Table>
        </TableContainer>
    )
}