import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { KonsilReport } from "../data_access/entities/konsil_report";
import { KonsilReportService } from "../data_access/services/konsil_report_service";
import { AppThunk } from "./store";
import { handleError } from "./user_reducer";

export interface IKonsilReportState {
    allKonsilReports: KonsilReport[];
    currentReport: KonsilReport;
}

const initialState: IKonsilReportState = {
    allKonsilReports: [],
    currentReport: {} as KonsilReport
}

export const konsilReportSlice = createSlice({
    name: "konsilReports",
    initialState,
    reducers: {
        setAllKonsilReports: (state, action: PayloadAction<KonsilReport[]>) => {
            state.allKonsilReports = action.payload
        },

        setCurrentReport: (state, action: PayloadAction<KonsilReport>) => {
            state.currentReport = action.payload
        }
    }
})

export const getAllKonsilReports = (): AppThunk => (dispatch, getState) => {
    new KonsilReportService().getAllReports()
    .then((response: KonsilReport[]) => {
        dispatch(setAllKonsilReports(response));
    })
    .catch((error: any) => {
        dispatch(handleError(error));
    })
}

export const getCurrentReport = (id: string): AppThunk => (dispatch, getState) => {
    new KonsilReportService().getReport(id)
    .then((response: KonsilReport) => {
        dispatch(setCurrentReport(response));
    })
    .catch((error: any) => {
        dispatch(handleError(error));
    })
}

export const { setAllKonsilReports, setCurrentReport } = konsilReportSlice.actions;
export default konsilReportSlice.reducer;