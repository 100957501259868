import { User, UserCredentials } from '../entities/user';
import { TelescanConfig } from "../telescan_config";
import { modifiedRequest } from './modified_request';

export class UserService {
    private resourceUrl = TelescanConfig.getInstance().getConstant('SERVER_API_URL');

    public convertUserCredentialsFromServer(json: any): UserCredentials {
        const user: UserCredentials = {} as UserCredentials;
        return Object.assign(user, json);
    }

    private convertUserFromServer(json: any): User {
        const user: User = Object.assign({} as User, json);
        return user;
    }

    private processList(jsonResponse: any): User[] {
        if (jsonResponse === null)
            return [];
        const listUser: User[] = [];
        for (let i = 0; i < jsonResponse.length; i++) {
            listUser.push(this.convertUserFromServer(jsonResponse[i]));
        }
        return listUser;
    }

    public login = async(username: string, password: string): Promise<any> => {  
        const response = await(             
            modifiedRequest("post", `${this.resourceUrl}/login`)
            .send({
                username: username,
                password: password
            })
        );          
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }               
        return this.convertUserCredentialsFromServer(response.body);
    }

    public logout(): void {
        // remove user from config storage to log user out
        const telescanConfig: TelescanConfig = TelescanConfig.getInstance();
        telescanConfig.setConstant("ACCESS_TOKEN", "");
        telescanConfig.setConstant("REFRESH_TOKEN", "");
        telescanConfig.setConstant("USERNAME", "");
        telescanConfig.setConstant("BSNR", "");
        telescanConfig.setConstant("LANR", "");
    }

    public setNewOwnPassword = async(currentPassword: string, newPassword: string): Promise<any> => {
        const response = await(
            modifiedRequest('put', `${this.resourceUrl}/user/changePassword/v1.0`)
            .send({
                oldPassword: currentPassword,
                newPassword: newPassword,
            })
        );
        if (!response.ok)
            throw new Error(`HTTP error! status: ${response.status}`);
        return this.convertUserFromServer(response.body);
    }

    public getAllUsers = async(): Promise<User[]> => {
        const response = await(
            modifiedRequest('get', `${this.resourceUrl}/user/v1.0`)
        );
        if (!response.ok)
            throw new Error(`HTTP error! status: ${response.status}`);
        return this.processList(response.body);
    }

    public setNewUser = async (newUser: User): Promise<User> => {
        const response = await(
            modifiedRequest('post', `${this.resourceUrl}/user/v1.0`)
            .send({userDTO: newUser})
        );
        if (!response.ok)
            throw new Error(`HTTP error! status: ${response.status}`);
        return this.convertUserFromServer(response.body);
    }

    public updateUser = async (user: User): Promise<User> => {
        const response = await(
            modifiedRequest('put', `${this.resourceUrl}/user/v1.0`)
            .send({user: user})
        );
        if (!response.ok)
            throw new Error(`HTTP error! status: ${response.status}`);
        return this.convertUserFromServer(response.body);
    }

    public deleteUser = async (id: string): Promise<any> => {
        const response = await(
            modifiedRequest('delete', `${this.resourceUrl}/user/${id}/v1.0`)
        );
        if (!response.ok)
            throw new Error(`HTTP error! status: ${response.status}`);
        return "ok";
    }

    public resetUserPassword = async (id: string, newPassword: string): Promise<any> => {
        const response = await(
            modifiedRequest('put', `${this.resourceUrl}/user/resetPassword/${id}/v1.0`)
            .send({newPassword: newPassword})
        );
        if (!response.ok)
            throw new Error(`HTTP error! status: ${response.status}`);
        return ("ok");
    }

    public getUserByName = async (username: string): Promise<any> => {
        const response = await(
            modifiedRequest('get', `${this.resourceUrl}/user/byName/${username}/v1.0/`)
            .send({username: username})
        );
        if (!response.ok)
            throw new Error(`HTTP error! status: ${response.status}`);
        return this.convertUserFromServer(response.body);
    }

    public sendTestMail = async (email: string): Promise<any> => {
        const response = await(
            modifiedRequest('post', `${this.resourceUrl}/email/test/`)
            .send({address: email})
        );
        if (!response.ok)
            throw new Error(`HTTP error! status: ${response.status}`);
        return ("ok");
    }

}