import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from "@mui/material";

interface IProps {
    isOpen: boolean,
    hanldeCloseDialog: () => void;
    handleConfirmButton: () => void;
    password: string;
    handleInputPasswordChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const ResetPasswordDialog = (props: IProps) => {
    return (
        <Dialog open = {props.isOpen}>
            <DialogTitle>
                Passwort zurücksetzen
            </DialogTitle>
            <DialogContent>
                <Typography>
                    Sie möchten den Passwort zurücksetzen. Geben Sie neues Passwort ein
                </Typography>
                <TextField
                    id = "password"
                    label = "Neues Passwort"
                    value = {props.password || ""}
                    type = "password"
                    name = "password"
                    autoComplete="newPassword"
                    variant = "standard"
                    onChange = {props.handleInputPasswordChange}
                    error = {!props.password || props.password === "" || props.password.length < 9 }
                    helperText="Mind. 8 Symbole"
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button
                    variant = "contained"
                    onClick = {props.handleConfirmButton}
                >
                    Zurücksetzen 
                </Button>
                <Button
                    onClick={props.hanldeCloseDialog}
                >
                    Abbrechen
                </Button>
            </DialogActions>
        </Dialog>
    )
}