import { Box, Button, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { getUserCredentials } from "../redux/user_reducer";
import Logo from '../static/images/logo-telescan-directory-manager.svg';
import { CustomerAlertComponent } from "./elements/customer_alert";
export const Login = () => {
    const [username, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();
    const user = useAppSelector(state => state.users.currentUserCredentials);
    const failures = useAppSelector(state => state.general.failures)
    useEffect(() => {
        if (user.access_token && user.access_token !== "")
            navigate("/");
    }, [user, navigate])
    const dispatch = useAppDispatch();
    const login = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.preventDefault();
        dispatch(getUserCredentials(username, password));
    }
    return (
        <Box className="login-wrapper">
            <CustomerAlertComponent failures = {failures}/>
            <Box className="login-side">
                <h1>Login</h1>
                <Box className = 'flex-column'>
                    <Typography
                        align="center"
                        className="version"
                        variant="caption"
                        color="textSecondary"
                    >
                        Version: 1.0.0
                    </Typography>
                </Box>
            </Box>
            <Box className="login-input">
                
                <img className="login-logo" src={Logo} alt="Logo"/>
                <form noValidate autoComplete="off">
                    <TextField
                        name="login"
                        label="Login"
                        value = {username}
                        onChange = {(e) => setUserName(e.target.value)} 
                    />

                    <TextField
                        className = 'remove-autocomplete-shadow'
                        name="password"
                        label="Passwort"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        type = "password"
                    />
                     <Box className="button-container">
                        <Button
                            variant="contained"
                            color="primary"
                            className="login-btn"
                            onClick={login}
                            type = "submit"
                            >
                            Anmelden
                        </Button>
                    </Box>
                </form>
            </Box>
        </Box>
    )
}
