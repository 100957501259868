import { Dermatologist } from '../entities/dermatologists';
import { TelescanConfig } from "../telescan_config";
import { modifiedRequest } from './modified_request';

export class DermatologistService {
    private resourceUrl = `${TelescanConfig.getInstance().getConstant('SERVER_API_URL')}/dermatologist`;

    private convertDermatologistFromServer(json: any): Dermatologist {
        const dermatologist: Dermatologist = Object.assign({} as Dermatologist, json);
        return dermatologist;
    }

    private processList(jsonResponse: any): Dermatologist[] {
        if (jsonResponse === null)
            return [];
        const listDermatologist: Dermatologist[] = [];
        for (let i = 0; i < jsonResponse.length; i++) {
            listDermatologist.push(this.convertDermatologistFromServer(jsonResponse[i]));
        }
        return listDermatologist;
    }


    // public getDermatologists = async(): Promise<any> => {
    //     try {
    //         const response = await(modifiedRequest('get', `${this.resourceUrl}/all/v1.0/`));
    //         return this.processList(response.body);
    //     } catch (error: any) {
    //         console.log(error);
    //     }
    // }

    public getAllDermatologists = async(): Promise<any> => {
        const response = await(modifiedRequest('get', `${this.resourceUrl}/all/v1.0/`));
        if (!response.ok)
            throw new Error(`HTTP error! status: ${response.status}`);
        return this.processList(response.body);
    }

    public getDermatologist = async(id: string): Promise<any> => {
        const response = await(modifiedRequest('get', `${this.resourceUrl}/${id}/v1.0/`));
        if (!response.ok)
            throw new Error(`HTTP error! status: ${response.status}`);
        return this.convertDermatologistFromServer(response.body);
    }

    public saveDermatologist = async(dermatologist: Dermatologist): Promise<any> => {
        const response = await(
            modifiedRequest('post', `${this.resourceUrl}/v1.0/`)
            .send({
               lanr: dermatologist.lanr,
               bsnr: dermatologist.bsnr,
               title: dermatologist.title,
               firstName: dermatologist.firstName,
               lastName: dermatologist.lastName,
               postalCode: dermatologist.postalCode,
               city: dermatologist.city,
               medicalOfficeName: dermatologist.medicalOfficeName,
               emailAddress: dermatologist.emailAddress
            })
        );
        if (!response.ok)
            throw new Error(`HTTP error! status: ${response.status}`);
        return "ok";
    }

    public updateDermatologist = async(id: string, dermatologist: Dermatologist): Promise<any> => {
        const response = await(
            modifiedRequest('put', `${this.resourceUrl}/${id}/v1.0/`)
            .send({
               lanr: dermatologist.lanr,
               bsnr: dermatologist.bsnr,
               title: dermatologist.title,
               firstName: dermatologist.firstName,
               lastName: dermatologist.lastName,
               postalCode: dermatologist.postalCode,
               city: dermatologist.city,
               medicalOfficeName: dermatologist.medicalOfficeName,
               emailAddress: dermatologist.emailAddress
            })
        );
        if (!response.ok)
            throw new Error(`HTTP error! status: ${response.status}`);
        return "ok";
    }

    public deleteDermatologist =async (id: string): Promise<any>  => {
        const response = await(
            modifiedRequest('delete', `${this.resourceUrl}/${id}/v1.0/`)
        );
        if (!response.ok)
            throw new Error(`HTTP error! status: ${response.status}`);
        return "ok";
    }

    public deactivateDermatologist =async (id: string): Promise<any>  => {
        const response = await(
            modifiedRequest('post', `${this.resourceUrl}/${id}/deactivate/v1.0/`)
        );
        if (!response.ok)
            throw new Error(`HTTP error! status: ${response.status}`);
        return this.convertDermatologistFromServer(response.body);
    }
}