import { Box, Button } from "@mui/material"
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { logout } from "../../redux/user_reducer";

export const MenuComponent = () => {
    const dispatch = useAppDispatch();

    const user = useAppSelector(state => state.users.currentUserCredentials);

    const navigate = useNavigate();

    useEffect(() => {
        if (!user.access_token || user.access_token === "")
            navigate("/login");
    }, [user, navigate]);

    const handleLogoutButton = () => {
        dispatch(logout());
    }

    const handleSettingsButton = () => {
        navigate("/settings");
    }

    return (
        <Box className = "user-drawer">
            <Button
                onClick = {handleLogoutButton}
                className = "logout-btn"
            >
                Logout
            </Button>
            <Button
                onClick = {handleSettingsButton}
                className = "logout-btn"
            >
                Einstellungen
            </Button>
        </Box>
        
    )
}