import { Provider } from "react-redux";
import { Shell } from "./components/shell";
import { store } from "./redux/store";
import { CssBaseline } from "@mui/material";
import './App.scss';

function App() {
    return (
        <Provider store = {store}>
            <div className="App">
                <CssBaseline>                  
                    <Shell/>
                </CssBaseline>        
            </div>          
        </Provider>
    )
}

export default App;