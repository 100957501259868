import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { CustomerAlertComponent } from "../elements/customer_alert";
import { SuccessAlert } from "../elements/success_alert";
import { HeaderDashboard } from "./header_dashboard";
import { HeaderSettings } from "./header_settings";
import { useIdleTimer } from 'react-idle-timer'; // library to track user activity, mouse and keyboard actions, needs to be added in the first node of react tree, it tracks activity in all children
import { useState } from "react";
import { logout } from "../../redux/user_reducer";

export const HeaderMain = () => {
    const failures = useAppSelector(state => state.general.failures);
    const successMessage = useAppSelector(state => state.general.successMessage);
    const dispatch = useAppDispatch();
    const location = useLocation();

    const timeout = 5*60*1000;
    const delayBeforeLogout = 30*1000;
    const [isShowLogoutWarning, setIsShowLogoutWarning] = useState(false);
    const [timeoutId, setTimeoutId] = useState<number>(0);

    const onIdle = () => { //Function to call when user is now idle => show dialog warning about logout in 30 sec, if user do not click ok -> logout
        setIsShowLogoutWarning(true)
        const timeoutIdLocal: number = window.setTimeout(() => dispatch(logout()), delayBeforeLogout);
        setTimeoutId(timeoutIdLocal);
    }

    useIdleTimer({
        timeout,
        onIdle: onIdle
      })

    const handleCancelInactivityWarning = () => {
        clearTimeout(timeoutId);
        setIsShowLogoutWarning(false);
    }

    return (
        <Box>
            <Dialog open = {isShowLogoutWarning}>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Sie werden aufgrund von Inaktivität in 30 Sekunden ausgeloggt.
                        Klicken Sie auf "Fortsetzen", um die automatische Abmeldung abzubrechen.
                    </DialogContentText>
                    </DialogContent>
                <DialogActions>
                    <Button
                        variant = 'contained'
                        color = 'primary'
                        onClick = {handleCancelInactivityWarning}
                    >
                        Fortsetzen
                    </Button>
                </DialogActions>
            </Dialog>
            <CustomerAlertComponent failures = {failures}/>
            <SuccessAlert successMessage = {successMessage}/>
            {location.pathname === "/" && <HeaderDashboard/>}
            {location.pathname === "/settings" && <HeaderSettings/>}
        </Box>
    )
}