import { Delete } from "@mui/icons-material"
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, IconButton, TablePagination } from "@mui/material"
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions"
import { Dermatologist } from "../../data_access/entities/dermatologists";

interface IProps {
    listDermatologists: Dermatologist[];
    handleTableRowClick: (id: string) => void;
    handleDeleteButton: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => void;
}
export const DermatologistPanel = (props: IProps) => {
    return (
        <TableContainer className="dashboard-table">
            {/* <Box className="mobile-table-header">
                <TableSort />
                <IconButton aria-label="delete">
                    <SortByAlpha />
                </IconButton>
            </Box> */}
            <Table stickyHeader aria-label="sticky table">
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>LANR/BSNR</TableCell>
                        <TableCell>Ort</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell></TableCell>
                        {/* <SortTableComponent activateCell={this.setPropertyNameAndDirection} active={this.state.propertyName === "patient"} propertyName="konsilPatient.vorname" title="Patient " />
                        <SortTableComponent activateCell={this.setPropertyNameAndDirection} active={this.state.propertyName === "konsilID"} propertyName="id" title="Konsil ID " />
                        <SortTableComponent activateCell={this.setPropertyNameAndDirection} active={this.state.propertyName === "zeitpunkt"} propertyName="datecreated" title="Zeitpunkt " />
                        <SortTableComponent activateCell={this.setPropertyNameAndDirection} active={this.state.propertyName === "konsilStatus"} propertyName="konsilStatus" title="Status " />
                        <SortTableComponent activateCell={this.setPropertyNameAndDirection} active={this.state.propertyName === "adressat"} propertyName="beauftragender_arzt.nachname" title="Adressat " /> */}
                        <TableCell align="right"></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.listDermatologists.map(rowData => {                         
                        return (
                        <TableRow
                            className={"table-row"}
                            key={rowData.id}
                            onClick = {() => props.handleTableRowClick(rowData.id || "")}
                            //onClick={(event) => this.konsil(rowData.id)} //style={rowData.konsil.statusAenderung? {backgroundColor: '#e7d1d1'}: {}}
                        >
                            <TableCell className="konsil-patient">
                                {`${rowData.title || ""} ${rowData.firstName || ""} ${rowData.lastName || ""}`}
                            </TableCell>
                            <TableCell className="konsil-id" scope="row">
                                {`${rowData.lanr || ""} / ${rowData.bsnr || ""}`}
                            </TableCell>
                            <TableCell className="konsil-date">
                                {`${rowData.postalCode || ""} ${rowData.city || ""}`}
                            </TableCell>
                            <TableCell className="konsil-date">
                            {rowData.status ? rowData.status : "unbekannt" }
                            </TableCell>
                            <TableCell align="right"></TableCell>
                            <TableCell>
                                <IconButton
                                    onClick = {(event) => props.handleDeleteButton(event, rowData.id || "")}
                                >
                                    <Delete/>
                                </IconButton>
                            </TableCell>
                        </TableRow>)}
                    )}
                    <TableRow className="table-footer-pagination">
                        {/* <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            colSpan={7}
                            count={50}
                            rowsPerPage={10}
                            page={1}
                            onPageChange={() => console.log("error")}
                            onRowsPerPageChange={() => console.log("error")}
                            ActionsComponent={TablePaginationActions}
                        /> */}
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )
}