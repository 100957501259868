import { Add, Delete } from "@mui/icons-material"
import { Box, Typography, Tooltip, Button, Paper, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, SelectChangeEvent } from "@mui/material"
import { useState } from "react";
import { User, UserRoles } from "../../data_access/entities/user"
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { deleteUser, resetUserPassword, setNewUser, updateUser } from "../../redux/user_reducer";
import { DeleteConfirmationDialog } from "../elements/delete_confirmation_dialog";
import { ManageUserDialog } from "./manage_user_dialog";
import LockResetIcon from '@mui/icons-material/LockReset';
import { ResetPasswordDialog } from "../elements/reset_password_dialog";

interface IProps {
    allUsers: User[],
}

export const UserManagement = (props: IProps) => {

    const currentUserCredentials = useAppSelector(state => state.users.currentUserCredentials);
    const dispatch = useAppDispatch();

    const [newUserLocal, setNewUserLocal] = useState<User>({});
    const [isAddUserDialog, setIsAddUserDialog] = useState(false);
    const [isAddFlag, setIsAddFlag] = useState(false);
    const [isDeleteDialog, setIsDeleteDialog] = useState(false);
    const [isResetPasswordDialog, setIsResetPasswordDialog] = useState(false);
    const [idUserToManage, setIdUserToManage] = useState("");
    const [newPasswordForReset, setNewPasswordForReset] = useState("");
    const [isSelfDisabled, setIsSelfDisabled] = useState(false);

    const handleAddNewUserButton = (tableRowId?: string) => {
        setIsAddUserDialog(true);
        if (tableRowId) {
            setIsAddFlag(false);
            const allUsersTemp: User[] = [...props.allUsers];
            const selectedUser = allUsersTemp.filter(item => item.id === tableRowId)[0];
            selectedUser && setNewUserLocal(selectedUser);
            if (selectedUser.username === currentUserCredentials.username) {
                setIsSelfDisabled(true);
            }
        } else {
            setIsAddFlag(true);
            setNewUserLocal({} as User);
        }
    }
    
    const closeAddUserDialog = () => {
        setIsAddUserDialog(false);
        setIsSelfDisabled(false);
    }

    const handleAddEditUserButton = (newUser: User) => {
        setIsAddUserDialog(false);
        setIsSelfDisabled(false);
        if (!newUser.id) {
            dispatch(setNewUser(newUser));
        } else {
            dispatch(updateUser(newUser));
        }
        
        setNewUserLocal({} as User);
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewUserLocal(newUserLocal => ({
            ...newUserLocal,
            [event.target.name]: event.target.value
        }))
    }
    
    const handleRoleFieldChange = (event: SelectChangeEvent) => {
        setNewUserLocal(newUserLocal => ({
            ...newUserLocal,
            role: event.target.value as UserRoles
        }))
    }

    const handleDeleteUserButton = (event: React.MouseEvent<HTMLButtonElement>, id: string) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDeleteDialog(true);
        setIdUserToManage(id);
    }

    const handleConfirmDeleteUser = () => {
        setIsDeleteDialog(false);
        dispatch(deleteUser(idUserToManage));
        setIdUserToManage("");
    }

    const handleCloseDeleteDialog = () => {
        setIsDeleteDialog(false);
        setIdUserToManage("");
    }

    const handleResetPasswordButton = (event: React.MouseEvent<HTMLButtonElement>, id: string) => {
        event.preventDefault();
        event.stopPropagation();
        setIsResetPasswordDialog(true);
        setIdUserToManage(id);
    }

    const handleCloseResetPasswordDialog = () => {
        setIsResetPasswordDialog(false);
        setIdUserToManage("");
        setNewPasswordForReset("");
    }

    const handleConfirmResetUser = () => {
        setIsResetPasswordDialog(false);
        dispatch(resetUserPassword(idUserToManage, newPasswordForReset));
        setIdUserToManage("");
        setNewPasswordForReset("");
    }

    const handleInputPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewPasswordForReset(event.target.value);
    }

    return (
        <Box className="setting-unit-container">
            <Box className="flex-box-header1">
                <Typography className="txt-header" variant="subtitle1" color="inherit">
                    Benutzerverwaltung
                </Typography>
            
                <Tooltip title="Neuen Benutzer hinzufügen">
                    <Button
                        className="slim"
                        variant="contained"
                        color="primary"
                        onClick = {() => handleAddNewUserButton()}
                    >
                        <Add/>
                    </Button>
                </Tooltip>               
                
            </Box>
            <Paper elevation={0}>
                <Typography className="setting-unit-text" variant="caption" color="inherit">
                    Hier können Sie die Daten der registrierten Nutzer ändern, einen Nutzer löschen, sowie einen neuen Nutzer anlegen.
                </Typography>
                <TableContainer className="table-in-settings selectable-rows">
                    <Table className="table-connector" stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Benutzername</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Rolle</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell align="right"></TableCell>
                            </TableRow>
                        </TableHead>
                            <TableBody>
                                {props.allUsers && props.allUsers.map((rowData: User) => {
                                    return (
                                        <TableRow
                                            hover
                                            key = {rowData.id}
                                            onClick = {() => handleAddNewUserButton(rowData.id || "")}
                                            id = "tableRowClick"
                                        >
                                            <TableCell>{rowData.username}</TableCell>
                                            <TableCell>{`${rowData.vorname || ""} ${rowData.name || ""}`}</TableCell>
                                            <TableCell>{rowData.role}</TableCell>
                                            <TableCell> {rowData.email} </TableCell>
                                            <TableCell align="right">
                                                <Box>
                                                    <Tooltip title="Benutzerpasswort zurücksetzen">
                                                        <span>
                                                            <IconButton
                                                                size="small"
                                                                onClick = {(event: React.MouseEvent<HTMLButtonElement>) => handleResetPasswordButton(event, rowData.id || "")}
                                                                disabled = {currentUserCredentials.username === rowData.username}
                                                            >
                                                                <LockResetIcon/>
                                                            </IconButton>
                                                        </span>                                                     
                                                    </Tooltip>
                                                    <Tooltip title="Benutzer löschen">
                                                        <span>
                                                            <IconButton
                                                                size="small"
                                                                onClick = {(event: React.MouseEvent<HTMLButtonElement>) => handleDeleteUserButton(event, rowData.id || "")}
                                                                disabled = {currentUserCredentials.username === rowData.username}
                                                            >
                                                                <Delete/>
                                                            </IconButton>  
                                                        </span>                                                     
                                                    </Tooltip>
                                                </Box>                                                   
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                              
                            </TableBody>
                    

                    </Table>
                </TableContainer>
                {/* <DirectoryServiceTable
                    hanldeOnRowClick={this.handleOnTableRowClick}
                    dermaRegistrationInfo = {this.props.dermaRegistrationInfo}
                    handleChangeEditFlag = {this.handleChangeEditFlag}
                /> */}
                
            </Paper>
            <ManageUserDialog
                isOpen = {isAddUserDialog}
                isAddFlag = {isAddFlag}
                closeAddUserDialog = {closeAddUserDialog}
                handleAddEditUserButton = {handleAddEditUserButton}
                newUser = {newUserLocal}
                handleInputChange = {handleInputChange}
                handleRoleFieldChange = {handleRoleFieldChange}
                isSelfDisabled = {isSelfDisabled}
            />
            <DeleteConfirmationDialog
                isOpen = {isDeleteDialog}
                dialogTitle = "Benutzer löschen"
                dialogContentText = "Sie werden Benutzer löschen. Sind Sie sicher?"
                hanldeCloseDialog = {handleCloseDeleteDialog}
                handleConfirmButton = {handleConfirmDeleteUser}
                confirmButtonText = {"Löschen"}
            />
            <ResetPasswordDialog
                isOpen = {isResetPasswordDialog}
                hanldeCloseDialog = {handleCloseResetPasswordDialog}
                handleConfirmButton = {handleConfirmResetUser}
                password = {newPasswordForReset}
                handleInputPasswordChange = {handleInputPasswordChange}
            />
        </Box>
    )
}