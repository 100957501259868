import { Box, Button, FormControl, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, TextField } from "@mui/material";
import { User, UserRoles } from "../../data_access/entities/user";
import { SimpleModal } from "../elements/simple_modal";

interface IProps {
    isOpen: boolean;
    isAddFlag: boolean;
    closeAddUserDialog: () => void;
    handleAddEditUserButton: (newUser: User) => void;
    newUser: User;
    handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleRoleFieldChange: (event: SelectChangeEvent) => void;
    isSelfDisabled: boolean;
}

export const ManageUserDialog = (props: IProps) => {

    const isDisabled = () => {
        if (props.isAddFlag) {
            return (
                !props.newUser.username || props.newUser.username === "" || props.newUser.username.length < 4 ||
                            !props.newUser.password || props.newUser.password === "" || props.newUser.password.length < 9 ||
                            !props.newUser.passwordRepeat || props.newUser.passwordRepeat === "" || props.newUser.passwordRepeat.length < 9 || props.newUser.password !== props.newUser.passwordRepeat||
                            !props.newUser.role
            )
        } else {
            return (
                !props.newUser.username || props.newUser.username === "" || props.newUser.username.length < 4 ||
                !props.newUser.role
            )
        }
    }
    
    return (
        <SimpleModal
            isOpen = {props.isOpen}
            additionalClassname="modal"
        >
            <Paper className="modal-wrapper">
                <h2 className="modal-header">Neuen Benutzer hinzufügen / verwalten</h2>
                <Box className = "modal-content">
                    <form className="add-form lab-form"
                        noValidate
                        autoComplete="off"
                    >
                        <Box className="text-field-grid2">
                            <TextField
                                id = "username"
                                label = "Benutzername"
                                value = {props.newUser.username || ""}
                                name = "username"
                                autoComplete="off"
                                variant = "standard"
                                onChange = {props.handleInputChange}
                                error = {!props.newUser.username || props.newUser.username === "" || props.newUser.username.length < 5}
                                helperText="Mind. 5 Zeichen"
                                disabled = {props.isSelfDisabled}
                            />
                          
                            {props.isAddFlag &&
                                <>
                                    <TextField
                                        id = "password"
                                        label = "Passwort"
                                        value = {props.newUser.password || ""}
                                        type = "password"
                                        name = "password"
                                        autoComplete="newPassword"
                                        variant = "standard"
                                        onChange = {props.handleInputChange}
                                        error = {!props.newUser.password || props.newUser.password === "" || props.newUser.password.length < 9 }
                                        helperText = "Mind. 8 verschiedenen(!) Zeichen. Darin enthalten sind mind. eine Zahl, ein Sonderzeichen, sowie ein Groß- und Kleinbuchstabe"
                                    />
                                    <TextField
                                        id = "passwordRepeat"
                                        label = "Passwort wiederholen"
                                        value = {props.newUser.passwordRepeat || ""}
                                        name = "passwordRepeat"
                                        type = "password"
                                        autoComplete="newPassword"
                                        variant = "standard"
                                        onChange = {props.handleInputChange}
                                        error = {!props.newUser.passwordRepeat || props.newUser.passwordRepeat === "" || props.newUser.passwordRepeat.length < 9 || props.newUser.password !== props.newUser.passwordRepeat}
                                    />
                                </>
                            }
                           
                            <Box className = "pad-top pad-bottom">
                                <FormControl fullWidth error = {!props.newUser.role} disabled = {props.isSelfDisabled}>
                                    <InputLabel id = "simple-select-label">Rolle</InputLabel>
                                    <Select
                                        variant = "standard"
                                        labelId = "simple-select-label"
                                        id = "role"
                                        value = {props.newUser.role ? props.newUser.role : ""}
                                        label="Rolle"
                                        onChange={props.handleRoleFieldChange}
                                    >
                                        <MenuItem value = {""}></MenuItem>
                                        <MenuItem value = {UserRoles.ROLE_ADMIN}>Admin</MenuItem>
                                        <MenuItem value = {UserRoles.ROLE_MANAGER}>Manager</MenuItem>
                                    </Select>
                                </FormControl>  
                            </Box>                   
                         
                            <TextField
                                id = "firstName"
                                label = "Vorname"
                                value = {props.newUser.vorname || ""}
                                name = "vorname"
                                autoComplete="off"
                                variant = "standard"
                                onChange = {props.handleInputChange}
                                // error = {!this.state.kimGeneralSettings.host || this.state.kimGeneralSettings.host === ""}
                            />
                            <TextField
                                id = "lastName"
                                label = "Name"
                                value = {props.newUser.name || ""}
                                name = "name"
                                autoComplete="off"
                                variant = "standard"
                                onChange = {props.handleInputChange}
                                // error = {!this.state.kimGeneralSettings.host || this.state.kimGeneralSettings.host === ""}
                            />
                            <TextField
                                id = "email"
                                label = "Email"
                                value = {props.newUser.email || ""}
                                name = "email"
                                autoComplete="off"
                                variant = "standard"
                                onChange = {props.handleInputChange}
                                fullWidth
                                // error = {!this.state.kimGeneralSettings.host || this.state.kimGeneralSettings.host === ""}
                            />
                         
                        </Box>
                    </form>
                </Box>
                <Box className="modal-footer">
                    <Button
                        variant="text"
                        color="primary"
                        onClick = {props.closeAddUserDialog}
                    >
                        Abbrechen
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick = {() => props.handleAddEditUserButton(props.newUser)}
                        disabled = {isDisabled()}
                    >
                        Hinzufügen / Aktualisieren{/* {this.props.add ? "Hinzufügen" : "Speichern"} */}
                    </Button>
                </Box>
            </Paper>
        </SimpleModal>
    )
}