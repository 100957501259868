import { Snackbar, Alert } from "@mui/material"
import { removeSuccessMessage } from "../../redux/general_reducer"
import { useAppDispatch } from "../../redux/hooks"

interface IProps {
    successMessage: string | null;
}
export const SuccessAlert = (props: IProps) => {
    const dispatch = useAppDispatch();
    return (
        <Snackbar
            anchorOrigin = {{vertical: "top", horizontal: "right"}}
            autoHideDuration = {10000}
            open = {props.successMessage != null}
            onClose = {() => {
                dispatch(removeSuccessMessage());
            }}
        >

            <Alert severity = "success" icon={<img src = {""} alt = ""/>}>
                <div>
                    {props.successMessage}
                </div>
            </Alert>

        </Snackbar>
    )
}