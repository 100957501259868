import { Modal, Backdrop, Fade } from "@mui/material"

interface IProps {
    isOpen: boolean;
    onClose?: (event?: any) => void;
    additionalClassname?: string;
    children: JSX.Element
}

export const SimpleModal: React.FC<IProps> = (props: IProps) => {
    return (
        <Modal
            open={props.isOpen}
            onClose={props.onClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={"modal " + props.additionalClassname}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
        <Fade in={props.isOpen}>
            {props.children}
        </Fade>
    </Modal>
    )
}