import { BaseEntity } from "./base_entity"


export enum UserRoles {
    ROLE_ADMIN = "ROLE_ADMIN",
    ROLE_MANAGER = "ROLE_MANAGER"
}

export interface UserCredentials extends BaseEntity {
    id?: string,
    username?: string,
    roles?: (keyof typeof UserRoles)[],
    access_token?: string,
    token_type?: string,
    expires_in?: number,
}

export interface User extends BaseEntity {
    id?: string,
    username?: string,
    role?: keyof typeof UserRoles,
    name?: string,
    vorname?: string,
    email?: string,
    dateCreated?: number,
    dateUpdated?: number,
    datePasswordSet?: number,
    password?: string,
    passwordRepeat?: string,
}