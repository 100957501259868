import { Snackbar, Alert } from "@mui/material"
import { Failure } from "../../data_access/entities/general"
import { removeFailure } from "../../redux/general_reducer"
import { useAppDispatch } from "../../redux/hooks"

interface IProps {
    failures: Failure[];
}
export const CustomerAlertComponent = (props: IProps) => {
    const dispatch = useAppDispatch();
    return (
        <Snackbar
            anchorOrigin = {{vertical: "top", horizontal: "right"}}
            autoHideDuration = {10000}
            open = {props.failures.length !== 0}
            onClose = {() => {
            props.failures[0].id && dispatch(removeFailure(props.failures[0].id))
            }}
        >
                <div>
                { //here all failures collected in redux store shown: main error and  messages if they exist
                    props.failures.map((item) => {
                    return(
                        item &&
                        <Alert severity = "error" key = {item.id} icon={<img src = {""} alt = ""/>}>
                            <div>
                                {item.errorText}
                            </div>
                        </Alert>
                )
                    })
                }
                </div>
        </Snackbar>
    )
}