import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { UserCredentials } from '../../data_access/entities/user';
import { useAppSelector } from '../../redux/hooks';
import Logo from '../../static/images/logo-telescan-directory-manager.svg';
import { UserComponent } from './user_component';

export const HeaderSettings = () => {
    const navigate = useNavigate();
    const userCredentials: UserCredentials = useAppSelector(state => state.users.currentUserCredentials);

    const handleBackButton = () => {
        navigate(-1);
    }

    return (
        <header className="header-settings">
            <h1 className="title">Einstellungen</h1>

            <img src={Logo} alt="Logo" className="logo"/>
            <UserComponent userDetails = {userCredentials}/>
            <Box className="title-actions">
                <Button onClick = {handleBackButton}>
                    Zurück
                </Button>
            </Box>

        </header>
    );
}
