import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useEffect, useState } from "react";
import { Dermatologist } from "../../data_access/entities/dermatologists";
import { KonsilReport } from "../../data_access/entities/konsil_report";
import { User, UserCredentials } from "../../data_access/entities/user";
import { deleteDermatologist, getAllDermatologists, getDermatologist } from "../../redux/dermatologist_reducer";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getAllKonsilReports, getCurrentReport } from "../../redux/konsil_report_reducer";
import { getCurrentUserByName, logout } from "../../redux/user_reducer";
import { DeleteConfirmationDialog } from "../elements/delete_confirmation_dialog";
import { NewPassword } from "../settings/new_password";
import { isEmptyObject } from "../utils";
import { DermatologistPanel } from "./dermatologists_panel";
import { KonsilReportPanel } from "./konsil_report_panel";
import { ManageDermaDialog } from "./manage_derma_dialog";
import { ShowReportDialog } from "./show_report_dialog";

export const Dashboard = () => {
    const listDermatologists: Dermatologist[] = useAppSelector(state => state.dermatologists.allDermatologists);
    const konsilReports: KonsilReport[] = useAppSelector(state => state.konsilReports.allKonsilReports);
    const dashboardTabNumber: number = useAppSelector(state => state.general.dashboardTabNumber);
    const user: UserCredentials = useAppSelector(state => state.users.currentUserCredentials);
    const currentUser: User = useAppSelector(state => state.users.currentUser);

    const dispatch = useAppDispatch();

    const [isManageDermaDialogOpen, setIsManageDermaDialogOpen] = useState(false);
    const [isShowReportDialogOpen, setIsShowReportDialogOpen] = useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [idToDelete, setIdToDelete] = useState("");
    const [isEditManageDermaDialog, setIsEditManageDermaDialog] = useState(false);

    useEffect(() => {
        user.username && dispatch(getCurrentUserByName(user.username));
        if (dashboardTabNumber === 0) {
            dispatch(getAllDermatologists());   
        } else if (dashboardTabNumber === 1) {
            dispatch(getAllKonsilReports());
        }
           
    }, [dispatch, dashboardTabNumber, user])

    const handleDeleteButton = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDeleteDialogOpen(true);
        setIdToDelete(id);
    }

    const handleConfirmDeleteDermatologist = () => {
        setIsDeleteDialogOpen(false);
        dispatch(deleteDermatologist(idToDelete));
        setIdToDelete("");
    }

    const handleCloseManageDermaDialog = () => {
        setIsManageDermaDialogOpen(false);
    }

    const handleCloseDeleteDialog = () => {
        setIsDeleteDialogOpen(false);
        setIdToDelete("");
    }

    const handleTableRowClick = (id: string) => {
        if (dashboardTabNumber === 0) {
            setIsManageDermaDialogOpen(true);
            dispatch(getDermatologist(id));
        } else if (dashboardTabNumber === 1) {
            setIsShowReportDialogOpen(true);
            dispatch(getCurrentReport(id));
        }
        
    }

    const handleCloseShowReportDialog = () => {
        setIsShowReportDialogOpen(false);
    }

    const handleIsEditManageDermaDialog = (isEditManageDermaDialog: boolean) => {
        setIsEditManageDermaDialog(isEditManageDermaDialog);
    }

    return (
        <Box className="dashboard-overview">
            <TabPanel value = {dashboardTabNumber} index = {0}>
                {listDermatologists ? 
                    <DermatologistPanel
                        listDermatologists = {listDermatologists}
                        handleTableRowClick = {handleTableRowClick}
                        handleDeleteButton = {handleDeleteButton}
                    /> :
                    <CircularProgress/>
                }
            </TabPanel>
            <TabPanel value = {dashboardTabNumber} index = {1}>
                {konsilReports ? 
                    <KonsilReportPanel
                        konsilReports = {konsilReports}
                        handleTableRowClick = {handleTableRowClick}
                    /> :
                    <CircularProgress/>
                }
            </TabPanel>
            <ManageDermaDialog
                isOpen = {isManageDermaDialogOpen}
                handleCloseAddDermaDialog = {handleCloseManageDermaDialog}
                isEdit = {isEditManageDermaDialog}
                handleIsEdit = {handleIsEditManageDermaDialog}
            />
            <ShowReportDialog
                isOpen = {isShowReportDialogOpen}
                handleCloseShowReportDialog = {handleCloseShowReportDialog}
            />
            <DeleteConfirmationDialog
                isOpen = {isDeleteDialogOpen}
                hanldeCloseDialog = {handleCloseDeleteDialog}
                handleConfirmButton = {handleConfirmDeleteDermatologist}
                dialogTitle = "Dermatologe löschen"
                dialogContentText = "Hiermit wird der Dermatologe aus dem Verzeichnisdienst gelöscht. Sind Sie sicher?"
                confirmButtonText = "Löschen"
            />
            <Dialog
                open = {!isEmptyObject(currentUser) && !currentUser.datePasswordSet}
                fullWidth
            >
                <DialogTitle>
                    Sie loggen sich erstmalig im TeleScan-Verzeichnisdienst ein und müssen Ihr Standard-Passwort ändern. 
                </DialogTitle>
                <DialogContent>
                    <NewPassword/>
                </DialogContent>
                <DialogActions className="modal-footer">
                    <Button onClick = {() => dispatch(logout())}>
                        Logout
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
       
    )
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  
export function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 0.5 }}>
                    {children}
                </Box>
            )}
        </div>
    );
  }


