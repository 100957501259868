import { Paper, Box, Button, Divider } from "@mui/material"
import { useAppSelector } from "../../redux/hooks";
import { SimpleModal } from "../elements/simple_modal"
import { ViewText } from "../elements/view_text";

interface IProps {
    isOpen: boolean;
    handleCloseShowReportDialog: () => void;
}

export const ShowReportDialog = (props: IProps) => {

    const currentKonsilReportStore = useAppSelector(state => state.konsilReports.currentReport);

    return (
        <SimpleModal
            isOpen = {props.isOpen}
            additionalClassname="modal"
        >
            <Paper className="modal-wrapper">
                <h2 className="modal-header">Detaillierte Ansicht</h2>
                <Box className = "modal-content">
                    <form className="add-form lab-form"
                        noValidate
                        autoComplete="off"
                    >
                        <Box className="label-content-grid">
                            <ViewText
                                label = "Konsil-ID"
                                text = {currentKonsilReportStore.identifier || ""}
                                labelVariant = "subtitle1"
                                hasError = {false}
                           />
                           <ViewText
                                label = "LANR / BSNR des Auftraggebers"
                                text = {`${currentKonsilReportStore.lanrHA || ""} / ${currentKonsilReportStore.bsnrHA || ""}`}
                                labelVariant = "subtitle1"
                                hasError = {false}
                           />
                            <ViewText
                                label = "LANR / BSNR des Konsiliars"
                                text = {`${currentKonsilReportStore.lanrDerma || "unbekannt"} / ${currentKonsilReportStore.bsnrDerma || "unbekannt"}`}
                                labelVariant = "subtitle1"
                                hasError = {false}
                           />
                            <ViewText
                                label = "Status des Konsilauftrags"
                                text = {currentKonsilReportStore.konsilStatus || ""}
                                labelVariant = "subtitle1"
                                hasError = {false}
                           />
                            <ViewText
                                label = "Therapiedringlichkeit"
                                text = {currentKonsilReportStore.therapyUrgency || ""}
                                labelVariant = "subtitle1"
                                hasError = {false}
                            />
                            <Divider/>
                            <ViewText
                                label = "Beauftragt"
                                text = {currentKonsilReportStore.dateBeauftragt ? new Date(currentKonsilReportStore.dateBeauftragt).toLocaleString() : ""}
                                labelVariant = "subtitle1"
                                hasError = {false}
                           />
                            <ViewText
                                label = "Rückfrage gestellt"
                                text = {currentKonsilReportStore.dateRueckfrage ? new Date(currentKonsilReportStore.dateRueckfrage).toLocaleString() : ""}
                                labelVariant = "subtitle1"
                                hasError = {false}
                           />
                            <ViewText
                                label = "Rückfrage beantwortet"
                                text = {currentKonsilReportStore.dateRueckfrageBeantwortet ? new Date(currentKonsilReportStore.dateRueckfrageBeantwortet).toLocaleString() : ""}
                                labelVariant = "subtitle1"
                                hasError = {false}
                           />
                            <ViewText
                                label = "Befundet"
                                text = {currentKonsilReportStore.dateBefundet ? new Date(currentKonsilReportStore.dateBefundet).toLocaleString() : ""}
                                labelVariant = "subtitle1"
                                hasError = {false}
                           />
                            <ViewText
                                label = "Befund abgerufen"
                                text = {currentKonsilReportStore.dateAbrufBefund ? new Date(currentKonsilReportStore.dateAbrufBefund).toLocaleString() : ""}
                                labelVariant = "subtitle1"
                                hasError = {false}
                           />
                            <ViewText
                                label = "Abgeschlossen"
                                text = {currentKonsilReportStore.dateAbgeschlossen ? new Date(currentKonsilReportStore.dateAbgeschlossen).toLocaleString() : ""}
                                labelVariant = "subtitle1"
                                hasError = {false}
                           />
                            {currentKonsilReportStore.dateAbgebrochen &&
                                <ViewText
                                    label = "Abbrechungsdatum"
                                    text = {new Date(currentKonsilReportStore.dateAbgebrochen).toLocaleString()}
                                    labelVariant = "subtitle1"
                                    hasError = {false}
                                />
                            }
                           
                            {currentKonsilReportStore.finished &&
                                    <ViewText
                                        label = "Bearbeitungsstand"
                                        text = "Fertig"
                                        labelVariant = "subtitle1"
                                        hasError = {false}
                                    />
                            }
                            {currentKonsilReportStore.deleted &&
                                <ViewText
                                    label = "Bearbeitungsstand"
                                    text = "Gelöscht"
                                    labelVariant = "subtitle1"
                                    hasError = {false}
                                />
                            }
                            <Divider/>
                            <ViewText
                                label = "Anzahl Bilder"
                                text = {currentKonsilReportStore.imagesCount ? currentKonsilReportStore.imagesCount.toString() :  ""}
                                labelVariant = "subtitle1"
                                hasError = {false}
                           />
                            <ViewText
                                label = "Qualität anamnestischer Angaben"
                                text = {currentKonsilReportStore.qualityAnamnese || ""}
                                labelVariant = "subtitle1"
                                hasError = {false}
                           />
                            <ViewText
                                label = "Bildqualität"
                                text = {currentKonsilReportStore.qualityImages || ""}
                                labelVariant = "subtitle1"
                                hasError = {false}
                           />
                            <ViewText
                                label = "Konsilqualität"
                                text = {currentKonsilReportStore.qualityReport || ""}
                                labelVariant = "subtitle1"
                                hasError = {false}
                           />
                        </Box>
                    </form>
                </Box>
                <Box className="modal-footer">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick = {props.handleCloseShowReportDialog}
                    >
                        OK
                    </Button>
                </Box>
            </Paper>
        </SimpleModal>
    )
}