import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import dermatologist_reducer from "./dermatologist_reducer";
import general_reducer from "./general_reducer";
import konsil_report_reducer from "./konsil_report_reducer";
import user_reducer from "./user_reducer";

export const store = configureStore({
    reducer: {
        general: general_reducer,
        dermatologists: dermatologist_reducer,
        konsilReports: konsil_report_reducer,
        users: user_reducer,
    }
})

  // Infer the `RootState` and `AppDispatch` types from the store itself
  export type RootState = ReturnType<typeof store.getState>
  // Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
  export type AppDispatch = typeof store.dispatch

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;