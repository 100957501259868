import { KonsilReport } from '../entities/konsil_report';
import { TelescanConfig } from "../telescan_config";
import { modifiedRequest } from './modified_request';

export class KonsilReportService {
    private resourceUrl = `${TelescanConfig.getInstance().getConstant('SERVER_API_URL')}/report`;

    public convertReportFromServer(json: any): KonsilReport {
        const konsilReport: KonsilReport = {} as KonsilReport;
        return Object.assign(konsilReport, json);
    }

    private processList(jsonResponse: any): KonsilReport [] {
        if (jsonResponse === null)
            return [];
        const listKonsilReport: KonsilReport [] = [];
        for (let i = 0; i < jsonResponse.length; i++) {
            listKonsilReport.push(this.convertReportFromServer(jsonResponse[i]));
        }
        return listKonsilReport;
    }

    public getAllReports = async(): Promise<any> => {  
        const response = await(             
            modifiedRequest("get", `${this.resourceUrl}/all/v1.0/`)
        );          
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }               
        return this.processList(response.body);
    }

    public getReport = async(id: string): Promise<any> => {  
        const response = await(             
            modifiedRequest("get", `${this.resourceUrl}/${id}/v1.0/`)
        );          
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }               
        return this.convertReportFromServer(response.body);
    }

}