import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Failure } from "../data_access/entities/general";

export interface IUserState {
    failures: Failure[];
    dashboardTabNumber: number;
    successMessage: string | null;
}

const initialState: IUserState = {
    failures: [],
    dashboardTabNumber: 0,
    successMessage: null,
}

export const generalSlice = createSlice({
    name: "general",
    initialState,
    reducers: {
        setFailure: (state, action: PayloadAction<Failure>) => {
            state.failures.push(action.payload);
        },

        removeFailure: (state, action: PayloadAction<string>) => {
            const filteredFailures = state.failures.filter(item => item.id !== action.payload)
            state.failures = filteredFailures
        },

        setDashboardTabNumber: (state, action: PayloadAction<number>) => {
            state.dashboardTabNumber = action.payload
        },

        setSuccessMessage: (state, action: PayloadAction<string>) => {
            state.successMessage = action.payload
        },

        removeSuccessMessage: (state) => {
            state.successMessage = null
        }
    }
})

export const { setFailure, removeFailure, setDashboardTabNumber, setSuccessMessage, removeSuccessMessage } = generalSlice.actions;
export default generalSlice.reducer;