import { Route, Routes, useLocation} from 'react-router-dom';
import { TelescanConfig } from '../data_access/telescan_config';
import { Dashboard } from './dashboard/dashboard';
import { HeaderMain } from './headers/header_main';
import { Login } from './login';
import { PrivateRoute } from './private_route';
import { SettingsOverview } from './settings/settings_overview';
export const Shell = () => {
    const telescanConfig: TelescanConfig = TelescanConfig.getInstance();
    const port: String = "8902";
    if (process.env.NODE_ENV === "development") {
        telescanConfig.setConstant("SERVER_API_URL", `${window?.location?.protocol ?? 'http:'}//${window?.location?.hostname ?? 'localhost'}:${port}/directory-manager`);
    } else {
        telescanConfig.setConstant("SERVER_API_URL", `${window?.location?.protocol ?? 'http:'}//${window?.location?.hostname ?? 'localhost'}:${window?.location?.port !== null ? window.location.port : port}/directory-manager`);
    }
    const location = useLocation();
    return (
        <>
            {(location.pathname !== '/login') &&
                <HeaderMain/>
            }
            <Routes>
                <Route
                    path="/"
                    element={
                    <PrivateRoute>
                        <Dashboard />
                    </PrivateRoute>
                    }
                />
                <Route path = "/login" element = {<Login />} />
                <Route
                    path="*"
                    element={
                    <PrivateRoute>
                        <Dashboard />
                    </PrivateRoute>
                    }
                />
                 <Route
                    path="/settings"
                    element={
                    <PrivateRoute>
                        <SettingsOverview />
                    </PrivateRoute>
                    }
                />
            </Routes>
        </>
       
    )
}