import { Box } from "@mui/material"
import { useEffect } from "react";
import { User, UserCredentials, UserRoles } from "../../data_access/entities/user";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getAllUsers } from "../../redux/user_reducer";
import { NewPassword } from "./new_password"
import { TestEmailSend } from "./test_email_send";
import { UserManagement } from "./user_management";

export const SettingsOverview = () => {

    const allUsersStore: User[] = useAppSelector(state => state.users.allUsers);
    const currentUserCredentials: UserCredentials = useAppSelector(state => state.users.currentUserCredentials);

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getAllUsers())    
    }, [dispatch])

    return (
        <Box className="main-content settings-content">               
            <Box className="settings-grid">
                <NewPassword/>
                {currentUserCredentials.roles && currentUserCredentials.roles.includes(UserRoles.ROLE_ADMIN) &&
                    <UserManagement
                        allUsers = {allUsersStore}
                    />
                }
                {currentUserCredentials.roles && currentUserCredentials.roles.includes(UserRoles.ROLE_ADMIN) &&
                    <TestEmailSend/>
                }
                

            </Box>
        </Box>
    )
}