import request from "superagent";
import { TelescanConfig } from "../telescan_config";

export const modifiedRequest = (method: 'get' | 'post' | 'delete' | 'put' = 'get', url: string) => {
    const token = TelescanConfig.getInstance().getConstant('ACCESS_TOKEN');
    if (token !== "") {
        return request(method, url).set('Authorization', 'Bearer ' + token);
    } else {
        return request(method, url)
    }
};