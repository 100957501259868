import { Box, Typography, Paper, TextField, IconButton } from "@mui/material"
import SendIcon from '@mui/icons-material/Send';
import { useState } from "react";
import { useAppDispatch } from "../../redux/hooks";
import { sendTestMail } from "../../redux/user_reducer";

export const TestEmailSend = () => {

    const dispatch = useAppDispatch();
    const [testMail, setTestMail] = useState("");

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTestMail(event.target.value);
    }
    return (
        <Box className="setting-unit-container">
            <Box className="flex-box-header1">
                <Typography className="txt-header" variant="subtitle1" color="inherit">
                    Testmail versenden
                </Typography>
            
                {/* <Tooltip title="Neuen Benutzer hinzufügen">
                    <Button
                        className="slim"
                        variant="contained"
                        color="primary"
                        //onClick = {() => handleAddNewUserButton()}
                    >
                        <Add/>
                    </Button>
                </Tooltip>                */}
                
            </Box>
            <Paper elevation={0}>
                <Box className = "testmail-arrangement">
                    <TextField
                        name = "testmail"
                        id = "testmail"
                        label = "E-Mail"
                        value = {testMail}
                        //fullWidth
                        onChange = {handleInputChange}
                    />                
                    <IconButton
                        size = "large"
                        color = "primary"
                        className = "margin-right-auto"
                        disabled = {!testMail || testMail === "" || !testMail.includes("@")}
                        onClick = {() => dispatch(sendTestMail(testMail))}
                    >
                        <SendIcon />
                    </IconButton>
                    
                </Box>           
            </Paper>
        </Box>
    )
}