import { CONSTANTS } from './constants';

export class TelescanConfig {
    private static instance: TelescanConfig;
    private constructor() { }

    public static getInstance(): TelescanConfig {
        if (!TelescanConfig.instance) {
            TelescanConfig.instance = new TelescanConfig();
        }

        return TelescanConfig.instance;
    }

    public getConstant(key: string) {
        return CONSTANTS[key];
    }

    public setConstant(key: string, value: string) {
        CONSTANTS[key] = value;
    }
}