import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Dermatologist } from "../data_access/entities/dermatologists";
import { DermatologistService } from "../data_access/services/dermatologist_service";
import { setSuccessMessage } from "./general_reducer";
import { AppThunk } from './store';
import { handleError } from "./user_reducer";

export interface IDermatologistState {
    allDermatologists: Dermatologist[];
    currentDermatologist: Dermatologist;
    isLoading: boolean;
}
const initialState: IDermatologistState = {
    allDermatologists: [],
    currentDermatologist: {} as Dermatologist,
    isLoading: false
}

export const dermatologistSlice = createSlice({
    name: "dermatologists",
    initialState,
    reducers: {
        setAllDermatologists: (state, action: PayloadAction<Dermatologist[]>) => {
            state.allDermatologists = action.payload
        },

        setCurrentDermatologist: (state, action: PayloadAction<Dermatologist>) => {
            state.currentDermatologist = action.payload
        },

        deleteDermatologistFromStore: (state, action: PayloadAction<string>) => {
            const filteredDermatologists = state.allDermatologists.filter(item => item.id !== action.payload)
            state.allDermatologists = filteredDermatologists;
        },

        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload
        }
    }
})

export const getAllDermatologists = (): AppThunk => (dispatch, getState) => {
    new DermatologistService().getAllDermatologists()
    .then((response: Dermatologist[]) => {
        dispatch(setAllDermatologists(response));
    })
    .catch((error: any) => {
        dispatch(handleError(error));
    })
}

export const getDermatologist = (id: string): AppThunk => (dispatch, getState) => {
    dispatch(setIsLoading(true));
    new DermatologistService().getDermatologist(id)
    .then((response: Dermatologist) => {
        dispatch(setIsLoading(false));
        dispatch(setCurrentDermatologist(response));
    })
    .catch((error: any) => {
        dispatch(setIsLoading(false));
        dispatch(handleError(error));
    })
}

export const saveDermatologist = (dermatologist: Dermatologist): AppThunk => (dispatch, getState) => {
    new DermatologistService().saveDermatologist(dermatologist)
    .then(() => {
        dispatch(getAllDermatologists());
        dispatch(setCurrentDermatologist({} as Dermatologist))
    })
    .catch((error: any) => {
        dispatch(handleError(error));
    })
}

export const updateDermatologist = (id: string, dermatologist: Dermatologist): AppThunk => (dispatch, getState) => {
    new DermatologistService().updateDermatologist(id, dermatologist)
    .then(() => {
        dispatch(getAllDermatologists());
        dispatch(setCurrentDermatologist({} as Dermatologist))
    })
    .catch((error: any) => {
        dispatch(handleError(error));
    })
}

export const deleteDermatologist = (id: string): AppThunk => (dispatch, getState) => {
    new DermatologistService().deleteDermatologist(id)
    .then(() => {
        dispatch(deleteDermatologistFromStore(id));
    })
    .catch((error: any) => {
        dispatch(handleError(error));
    })
}

export const deactivateDermatologist = (id: string): AppThunk => (dispatch, getState) => {
    new DermatologistService().deactivateDermatologist(id)
    .then((response: Dermatologist) => {
        dispatch(setSuccessMessage("Dermatologe wurde erfolgreich deaktiviert"));
        dispatch(setCurrentDermatologist(response));
    })
    .catch((error: any) => {
        dispatch(handleError(error));
    })
}

export const { setAllDermatologists, setCurrentDermatologist, deleteDermatologistFromStore, setIsLoading } = dermatologistSlice.actions;
export default dermatologistSlice.reducer;

