import { Avatar, Box, Drawer, Typography } from "@mui/material"
import { useState } from "react";
import { UserCredentials } from "../../data_access/entities/user"
import { MenuComponent } from "./menu_component";

interface IProps {
    userDetails: UserCredentials
}

export const UserComponent = (props: IProps) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleAvatarClick = () => {
        setIsMenuOpen(true);
    }

    const handleMenuClose = () => {
        setIsMenuOpen(false);
    }
    return (
        <Box className="flex-row header-top-right">
             <Box
                className="user-content"
                //aria-describedby={this.state.id}
                onClick={handleAvatarClick}
            >
                <Box className="inner-user-content">
                    <Avatar
                        className="main-avatar"
                        alt={props.userDetails.username}
                        //src={this.props.avatar}
                    />
                    <Typography variant="subtitle1" color="inherit">
                        {props.userDetails.username}
                    </Typography>
                </Box>
            </Box>
            <Drawer
                className = "user-drawer-wrapper"
                anchor = "right"
                open = {isMenuOpen}
                onClose = {handleMenuClose}
            >
                {/* <UserPopupContentContainer userName={this.state.userName} 
                    avatar={this.state.avatar}
                    lanr={this.state.lanr}
                    bsnr={this.state.bsnr}/> */}
                <MenuComponent/>
            </Drawer>
        </Box>
       
    )
}