import { createSlice, PayloadAction } from "@reduxjs/toolkit"
//import { AppThunk } from "../store"
import { User, UserCredentials } from "../data_access/entities/user"
import { UserService } from "../data_access/services/user_service"
import { TelescanConfig } from "../data_access/telescan_config"
import { setFailure, setSuccessMessage } from "./general_reducer"
import { AppThunk } from "./store"
import {v4 as uuid} from 'uuid'
import { Failure } from "../data_access/entities/general"

export interface IUserState {
    currentUserCredentials: UserCredentials;
    allUsers: User[];
    currentUser: User;
}

const initialState: IUserState = {
    currentUserCredentials: {} as UserCredentials,
    allUsers: [],
    currentUser: {} as User,
}

export const userSlice = createSlice({
    name: "users",
    initialState,
    reducers: {
        setUserCredentials: (state, action: PayloadAction<UserCredentials>) => {
            state.currentUserCredentials = action.payload
        },

        setAllUsers: (state, action: PayloadAction<User[]>) => {
            state.allUsers = action.payload
        },

        addUser: (state, action: PayloadAction<User>) => {
            state.allUsers.push(action.payload)
        },

        deleteUserFromStore: (state, action: PayloadAction<string>) => {
            const filteredUsers = state.allUsers.filter(item => item.id !== action.payload)
            state.allUsers = filteredUsers;
        },
        
        setCurrentUser: (state, action: PayloadAction<User>) => {
            state.currentUser = action.payload
        }
    }
})

// export const incrementAsync = createAsyncThunk(
//     'counter/fetchCount',
//     async (amount: number) => {
//       const response = await fetchCount(amount);
//       // The value we return becomes the `fulfilled` action payload
//       return response.data;
//     }
// );

export const getUserCredentials = (username: string, password: string): AppThunk => (dispatch, getState) => {
    new UserService().login(username, password)
    .then((response: UserCredentials) => {
        const telescanConfig: TelescanConfig = TelescanConfig.getInstance();
        response.access_token ? telescanConfig.setConstant("ACCESS_TOKEN", response.access_token) : telescanConfig.setConstant("ACCESS_TOKEN", "");
        dispatch(setUserCredentials(response));
    })
    .catch((error: any) => {
        dispatch(handleError(error));
    })
}

export const handleError = (error: any): AppThunk => (dispatch, getState) => {
    const failure: Failure = {} as Failure;
    failure.id = uuid();
    if (error.status && (error.status === 401 || error.status === 403))
        failure.errorText = "Authentifizierung fehlgeschlagen! Bitte melden Sie sich erneut an.";
    else if (error.status && error.status.toString().startsWith("5"))
        failure.errorText = "Es ist ein interner Serverfehler aufgetreten. Weitere Informationen finden Sie in der Protokolldatei des TeleScan Servers.";
    else if (error.status && error.status === 400) {
        failure.errorText = error.response.body.error
    } else
        failure.errorText = String(error);
    dispatch(setFailure(failure));
}

export const logout = (): AppThunk => (dispatch, getState) => {
    new UserService().logout();
    dispatch(setUserCredentials({} as UserCredentials));
}

export const setNewOwnPassword = (currentPassword: string, newPassword: string): AppThunk => (dispatch, getState) => {
    new UserService().setNewOwnPassword(currentPassword, newPassword)
    .then((response: User) => {
        dispatch(setCurrentUser(response));
        dispatch(setSuccessMessage("Passwort wurde erfolgreich geändert"))
    })
    .catch((error: any) => {
        dispatch(handleError(error));
    })
}

export const getAllUsers = (): AppThunk => (dispatch, getState) => {
    new UserService().getAllUsers()
    .then((response: User[]) => {
        dispatch(setAllUsers(response))
    })
    .catch((error: any) => {
        dispatch(handleError(error));
    })
}

export const setNewUser = (newUser: User): AppThunk => (dispatch, getState) => {
    new UserService().setNewUser(newUser)
    .then((response: User) => {
        dispatch(addUser(response))
    })
    .catch((error: any) => {
        dispatch(handleError(error));
    })
}

export const updateUser = (user: User): AppThunk => (dispatch, getState) => {
    new UserService().updateUser(user)
    .then(() => {
        dispatch(getAllUsers())
    })
    .catch((error: any) => {
        dispatch(handleError(error));
    })
}

export const deleteUser = (id: string): AppThunk => (dispatch, getState) => {
    new UserService().deleteUser(id)
    .then(() => {
        dispatch(deleteUserFromStore(id))
    })
    .catch((error: any) => {
        dispatch(handleError(error));
    })
}

export const resetUserPassword = (id: string, newPassword: string): AppThunk => (dispatch, getState) => {
    new UserService().resetUserPassword(id, newPassword)
    .then(() => {
        dispatch(setSuccessMessage("Passwort wurde erfolgreich zurückgesetzt"))
    })
    .catch((error: any) => {
        dispatch(handleError(error));
    })
}

export const getCurrentUserByName = (username: string): AppThunk => (dispatch) => {
    new UserService().getUserByName(username)
    .then((response: User) => {
        dispatch(setCurrentUser(response));
    })
    .catch((error: any) => {
        dispatch(handleError(error));
    })
}

export const sendTestMail = (email: string): AppThunk => (dispatch) => {
    new UserService().sendTestMail(email)
    .catch((error: any) => {
        dispatch(handleError(error));
    })
}

export const { setUserCredentials, setAllUsers, addUser, deleteUserFromStore, setCurrentUser } = userSlice.actions;
export default userSlice.reducer;