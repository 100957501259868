import { Add, Replay } from '@mui/icons-material';
import { Box, Tooltip, Button, IconButton, Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import { Dermatologist } from '../../data_access/entities/dermatologists';
import { UserCredentials } from '../../data_access/entities/user';
import { getAllDermatologists, setCurrentDermatologist } from '../../redux/dermatologist_reducer';
import { setDashboardTabNumber } from '../../redux/general_reducer';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getAllKonsilReports } from '../../redux/konsil_report_reducer';
import Logo from '../../static/images/logo-telescan-directory-manager.svg';
import { ManageDermaDialog } from '../dashboard/manage_derma_dialog';
import { UserComponent } from './user_component';

export const HeaderDashboard = () => {
    const user: UserCredentials = useAppSelector(state => state.users.currentUserCredentials);
    const dashboardTabNumber: number = useAppSelector(state => state.general.dashboardTabNumber);

    const dispatch = useAppDispatch();

    const [isAddDermaDialogOpen, setIsAddDermaDialogOpen] = useState(false);

    const handleAddButtonClick = () => {
        dispatch(setCurrentDermatologist({} as Dermatologist));
        setIsAddDermaDialogOpen(true);
    }

    const handleCloseAddDermaDialog = () => {
        setIsAddDermaDialogOpen(false);
    }

    const handleRefreshButton = () => {
        if (dashboardTabNumber === 0) {
            dispatch(getAllDermatologists());
        } else if (dashboardTabNumber === 1) {
            dispatch(getAllKonsilReports());
        }       
    }

    const handleChangeTab = (_event: React.SyntheticEvent, newValue: number) => {
        dispatch(setDashboardTabNumber(newValue));
    }
    return (
        <header className="header-dashboard">
            {dashboardTabNumber === 0 &&
                <h1 className="title">Liste der Dermatologen</h1>
            }
            {dashboardTabNumber === 1 &&
                <h1 className="title">Konsilliste</h1>
            }
            <img src={Logo} alt="Logo" className="logo"/>
            <UserComponent userDetails = {user}/>
            <Box className="title-actions">
                <Box className="actions flex-row-center">      
                    {dashboardTabNumber === 0 &&
                        <Tooltip title="Neuen Dermatologe anlegen" placement="top">
                            <Button
                                className="add-konsil slim"
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={handleAddButtonClick}
                            >
                                <Add/>
                            </Button>
                        </Tooltip>
                    }                   
                    <Tooltip
                        title = {dashboardTabNumber === 0 ? "Liste der Dermatologen aktualisieren": "Konsilliste aktualisieren"}
                        placement="top">
                        <IconButton
                            size="small"
                            aria-label="Liste der Dermatologen aktualisieren"
                            onClick={handleRefreshButton}
                        >
                            <Replay/>
                        </IconButton>
                    </Tooltip>
                    <Box className="flex-spacer"></Box>                                              
                </Box>
            </Box>

            <Box sx={{ borderBottom: 1, borderColor: 'divider', bgcolor: 'background.paper' }} className = "dashboard-tabs">
                <Tabs
                    value={dashboardTabNumber}
                    onChange={handleChangeTab}
                    aria-label="dashboard tabs"
                >
                    <Tab label="Verzeichnisdienst" {...a11yProps(0)} />
                    <Tab label="Konsil-Metadaten" {...a11yProps(1)} />
                </Tabs>
            </Box> 
            <ManageDermaDialog
                isOpen = {isAddDermaDialogOpen}
                handleCloseAddDermaDialog = {handleCloseAddDermaDialog}
                isEdit = {true}
            />
           
        </header>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}