import { Button, FormControl, Paper, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { IPasswordState } from "../../data_access/entities/general";
import { useAppDispatch } from "../../redux/hooks";
import { setNewOwnPassword } from "../../redux/user_reducer";


export const NewPassword = () => {

    const dispatch = useAppDispatch();
    const [passwordState, setPasswordState] = useState<IPasswordState>({
        currentPassword: "",
        newPassword: "",
        newPasswordRepeat: ""
    })


    const handleChangePasswords = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPasswordState(passwordState => ({
            ...passwordState,
            [event.target.name]: event.target.value
        }))
    }

    const handleSetPasswordButtonClick = () => {
        dispatch(setNewOwnPassword(passwordState.currentPassword, passwordState.newPassword));
        setPasswordState(passwordState => ({
            ...passwordState,
            currentPassword: "",
            newPassword: "",
            newPasswordRepeat: "",
        }))
    }
    return (
        <form className="password-container setting-unit-container" noValidate autoComplete="off">
            <Typography className="txt-header" variant="subtitle1" color="inherit">
                Passwort ändern
            </Typography>
            <Paper className="set-password-card" elevation={0}>
                <Typography variant="caption" color="inherit" >
                    Legen Sie ein neues Login-Passwort fest.
                </Typography>
                <FormControl component="fieldset">
                    <TextField
                        name = "currentPassword"
                        id="old-pw-textarea"
                        label = "Aktuelles Passwort"
                        value={passwordState.currentPassword}
                        onChange={handleChangePasswords}
                        type="password"
                        autoComplete="new-password"
                    />
                    <TextField
                        name = "newPassword"
                        id="new-pw-textarea"
                        label = "Neues Passwort"
                        value={passwordState.newPassword}
                        onChange={handleChangePasswords}
                        type="password"
                        autoComplete="new-password"
                    />
                    <TextField
                        name = "newPasswordRepeat"
                        label = "Neues Passwort wiederholen"
                        value={passwordState.newPasswordRepeat}
                        onChange={handleChangePasswords}
                        type="password"
                        autoComplete="new-password"
                    />
                
                    <Typography variant="caption" color="inherit">
                        Ein starkes Passwort besteht aus 8 verschiedenen(!) Zeichen. 
                        Darin enthalten sind mindestens eine Zahl, ein Sonderzeichen, sowie ein Groß- und Kleinbuchstabe.
                    </Typography>
                </FormControl>
                <Button
                    variant="contained"
                    disabled={!passwordState.currentPassword || passwordState.currentPassword === "" ||
                        !passwordState.newPassword || passwordState.newPassword === "" ||
                        !passwordState.newPasswordRepeat || passwordState.newPasswordRepeat === "" ||
                        passwordState.newPassword !== passwordState.newPasswordRepeat
                    } 
                    onClick = {handleSetPasswordButtonClick}
                >
                    Neues Passwort setzen
                </Button>
            </Paper>
        </form>

       
    )
}