import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

interface IProps {
    isOpen: boolean,
    hanldeCloseDialog: () => void;
    handleConfirmButton: () => void;
    dialogTitle: string;
    dialogContentText: string;
    confirmButtonText: string;
}

export const DeleteConfirmationDialog = (props: IProps) => {
    return (
        <Dialog open = {props.isOpen}>
            <DialogTitle>
                {props.dialogTitle}
            </DialogTitle>
            <DialogContent>
                {props.dialogContentText}
            </DialogContent>
            <DialogActions>
                <Button
                    variant = "contained"
                    onClick = {props.handleConfirmButton}
                >
                    {props.confirmButtonText} 
                </Button>
                <Button
                    onClick={props.hanldeCloseDialog}
                >
                    Abbrechen
                </Button>
            </DialogActions>
        </Dialog>
    )
}