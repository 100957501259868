import { Navigate, useLocation } from "react-router-dom";
import { UserCredentials } from "../data_access/entities/user";
import { useAppSelector } from "../redux/hooks";

export const PrivateRoute = ({ children }: { children: JSX.Element }) => {
    const user: UserCredentials = useAppSelector(state => state.users.currentUserCredentials);
    const location = useLocation();
    if (!user.access_token || user.access_token === "") {
        return(
            <Navigate
                to = "/login"
                state = {{from: location}}
                replace
            />
        )
    }
    return children;
}