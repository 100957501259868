import { Box, Typography } from "@mui/material"

interface IProps {
    label: string;
    text: string;  
    labelVariant: "caption" | "body1" | "subtitle1";
    hasError: boolean;
}

export const ViewText = (props: IProps) => {
    return (
        <Box className="konsil-txt-container">
            <Typography
                className = "txt-label"
                variant={props.labelVariant}
                color={(props.hasError) ? "error" : "inherit"}
            >
                {props.label}
            </Typography>
              
            <Typography
                className="txt-content"
                variant="body1"
                color={(props.hasError) ? "error" : "inherit"}
                style={{whiteSpace: 'pre-line'}}
            >
                {props.text}
            </Typography>     
    </Box>
    )
}