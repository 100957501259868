import { Box, Button, CircularProgress, Paper, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Dermatologist } from "../../data_access/entities/dermatologists";
import { deactivateDermatologist, saveDermatologist, updateDermatologist } from "../../redux/dermatologist_reducer";
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { DeleteConfirmationDialog } from "../elements/delete_confirmation_dialog";
import { SimpleModal } from "../elements/simple_modal"
import { ViewText } from "../elements/view_text";

interface IProps {
    isOpen: boolean;
    handleCloseAddDermaDialog: () => void;
    isEdit: boolean;
    handleIsEdit?: (isEdit: boolean) => void;
}

export const ManageDermaDialog = (props: IProps) => {
    const dispatch = useAppDispatch();
    const currentDermatologistStore = useAppSelector(state => state.dermatologists.currentDermatologist);
    const isLoadind = useAppSelector(state => state.dermatologists.isLoading);

    const [isDeactivateDialogOpen, setIsDeactivateDialogOpen] = useState(false);
    const [localCurrentDermatologist, setLocalCurrentDermatologist] = useState({} as Dermatologist);

    useEffect(() => {
        setLocalCurrentDermatologist(currentDermatologistStore)
    }, [currentDermatologistStore]);

    const handleCancelClick = () => {
        if (props.isEdit && props.handleIsEdit) {
            props.handleIsEdit(false);
        } else
            props.handleCloseAddDermaDialog();
        //dispatch(setIsAddDermaDialogOpen(false));
    }

    const handleSaveClick = () => {
        if (!props.isEdit && props.handleIsEdit) {
            props.handleIsEdit(true);
        } else {
            if (currentDermatologistStore.id)
                dispatch(updateDermatologist(currentDermatologistStore.id, localCurrentDermatologist));
            else
                dispatch(saveDermatologist(localCurrentDermatologist));
            //dispatch(setIsAddDermaDialogOpen(false));
            props.handleCloseAddDermaDialog();
        }
       
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLocalCurrentDermatologist({
            ...localCurrentDermatologist,
            [event.target.name]: event.target.value
        })
    }

    const handleConfirmDeactivateDermatologist = () => {
        setIsDeactivateDialogOpen(false);
        dispatch(deactivateDermatologist(currentDermatologistStore.id || ""));
    }

    const handleCloseDeactivateDialog = () => {
        setIsDeactivateDialogOpen(false);
    }

    const handleDeactivateClick = () => {
        setIsDeactivateDialogOpen(true);
    }

    const disabledSubmitButton = (): boolean => {
        return (
            !localCurrentDermatologist.firstName || localCurrentDermatologist.firstName === "" ||
            !localCurrentDermatologist.lastName || localCurrentDermatologist.lastName === "" ||
            !localCurrentDermatologist.lastName || localCurrentDermatologist.lastName === "" ||
            !localCurrentDermatologist.lanr || localCurrentDermatologist.lanr === "" || localCurrentDermatologist.lanr.length !== 9 ||
            !localCurrentDermatologist.bsnr || localCurrentDermatologist.bsnr === "" || localCurrentDermatologist.bsnr.length !== 9 ||
            !localCurrentDermatologist.emailAddress || localCurrentDermatologist.emailAddress === "" ||
            !localCurrentDermatologist.postalCode || localCurrentDermatologist.postalCode === "" || localCurrentDermatologist.postalCode.length !== 5 ||
            !localCurrentDermatologist.city || localCurrentDermatologist.city === ""
        )
    }

    return (
        <SimpleModal
            isOpen = {props.isOpen}
            additionalClassname="modal"
        >
            <Paper className="modal-wrapper">
                <h2 className="modal-header">
                    {props.isEdit ? "Dermatologe hinzufügen / bearbeiten" : "Dermatologe im Verzeichnisdienst"}
                </h2>
                <Box className = "modal-content">
                    <form className="add-form lab-form"
                        noValidate
                        autoComplete="off"
                    >
                        {props.handleIsEdit &&
                             <Typography color = "error">
                                Achtung: Die Änderung dieser Daten wird nur einem Plausibilitätscheck unterzogen. Der Dermatologe bleibt weiterhin im Verzeichnisdienst aktiviert.
                                Sind die Änderungen fehlerhaft, kann dies dazu führen, dass Konsile nicht zugestellt werden können!
                                Bei Änderungen, die vom Dermatologen geprüft werden sollten (z.B. KIM Adresse), deaktivieren Sie bitte zuerst den Dermatologen!
                            </Typography>
                        }
                        <Box>
                        {isLoadind ?
                            <CircularProgress/> :
                            props.isEdit ?
                                <Box className="text-field-grid1">
                                    <TextField
                                        id = "title"
                                        label = "Titel"
                                        value = {localCurrentDermatologist.title || ""}
                                        name = "title"
                                        autoComplete="off"
                                        variant = "standard"
                                        onChange = {handleInputChange}
                                        disabled = {!props.isEdit}
                                    />
                                    <TextField
                                        id = "firstName"
                                        label = "Vorname"
                                        value = {localCurrentDermatologist.firstName || ""}
                                        name = "firstName"
                                        autoComplete="off"
                                        variant = "standard"
                                        onChange = {handleInputChange}
                                        error = {!localCurrentDermatologist.firstName || localCurrentDermatologist.firstName === ""}
                                        disabled = {!props.isEdit}
                                    />
                                    <TextField
                                        id = "lastName"
                                        label = "Nachname"
                                        value = {localCurrentDermatologist.lastName || ""}
                                        name = "lastName"
                                        autoComplete="off"
                                        variant = "standard"
                                        onChange = {handleInputChange}
                                        error = {!localCurrentDermatologist.lastName || localCurrentDermatologist.lastName === ""}
                                        disabled = {!props.isEdit}
                                    />
                                    <TextField
                                        id = "lanr"
                                        label = "LANR"
                                        value = {localCurrentDermatologist.lanr || ""}
                                        name = "lanr"
                                        autoComplete="off"
                                        variant = "standard"
                                        onChange = {handleInputChange}
                                        error = {!localCurrentDermatologist.lanr || localCurrentDermatologist.lanr === "" || localCurrentDermatologist.lanr.length !== 9}
                                        helperText = "9 Ziffern"
                                        disabled = {!props.isEdit}
                                    />
                                    <TextField
                                        id = "bsnr"
                                        label = "BSNR"
                                        value = {localCurrentDermatologist.bsnr || ""}
                                        name = "bsnr"
                                        autoComplete="off"
                                        variant = "standard"
                                        onChange = {handleInputChange}
                                        error = {!localCurrentDermatologist.bsnr || localCurrentDermatologist.bsnr === "" || localCurrentDermatologist.bsnr.length !== 9}
                                        helperText = "9 Ziffern"
                                        disabled = {!props.isEdit}
                                    />
                                    <TextField
                                        id = "emailAddress"
                                        label = "E-Mail (nicht KIM-Adresse!)"
                                        value = {localCurrentDermatologist.emailAddress || ""}
                                        name = "emailAddress"
                                        autoComplete="off"
                                        variant = "standard"
                                        onChange = {handleInputChange}
                                        fullWidth
                                        error = {!localCurrentDermatologist.emailAddress || localCurrentDermatologist.emailAddress === ""}
                                        disabled = {!props.isEdit}
                                    />
                                    <TextField
                                        id = "postalCode"
                                        label = "PLZ"
                                        value = {localCurrentDermatologist.postalCode || ""}
                                        name = "postalCode"
                                        autoComplete="off"
                                        variant = "standard"
                                        onChange = {handleInputChange}
                                        error = {!localCurrentDermatologist.postalCode || localCurrentDermatologist.postalCode === "" || localCurrentDermatologist.postalCode.length !== 5}
                                        helperText = "5 Ziffern"
                                        disabled = {!props.isEdit}
                                    />
                                    <TextField
                                        id = "city"
                                        label = "Ort"
                                        value = {localCurrentDermatologist.city || ""}
                                        name = "city"
                                        autoComplete="off"
                                        variant = "standard"
                                        onChange = {handleInputChange}
                                        error = {!localCurrentDermatologist.city || localCurrentDermatologist.city === ""}
                                        disabled = {!props.isEdit}
                                    />
                                    <TextField
                                        id = "medicalOfficeName"
                                        label = "Praxisname"
                                        value = {localCurrentDermatologist.medicalOfficeName|| ""}
                                        name = "medicalOfficeName"
                                        autoComplete="off"
                                        variant = "standard"
                                        onChange = {handleInputChange}
                                        disabled = {!props.isEdit}
                                    />
                                </Box>
                                :
                                <Box className="label-content-grid">
                                    <ViewText
                                        label = "Titel"
                                        text = {localCurrentDermatologist.title || ""}
                                        labelVariant = "subtitle1"
                                        hasError = {false}
                                    />
                                    <ViewText
                                        label = "Vorname"
                                        text = {localCurrentDermatologist.firstName || ""}
                                        labelVariant = "subtitle1"
                                        hasError = {false}
                                    />
                                    <ViewText
                                        label = "Nachname"
                                        text = {localCurrentDermatologist.lastName || ""}
                                        labelVariant = "subtitle1"
                                        hasError = {false}
                                    />
                                    <ViewText
                                        label = "LANR"
                                        text = {localCurrentDermatologist.lanr || ""}
                                        labelVariant = "subtitle1"
                                        hasError = {false}
                                    />
                                    <ViewText
                                        label = "BSNR"
                                        text = {localCurrentDermatologist.bsnr || ""}
                                        labelVariant = "subtitle1"
                                        hasError = {false}
                                    />
                                    <ViewText
                                        label = "E-Mail (nicht KIM-Adresse!)"
                                        text = {localCurrentDermatologist.emailAddress || ""}
                                        labelVariant = "subtitle1"
                                        hasError = {false}
                                    />
                                    <ViewText
                                        label = "PLZ"
                                        text = {localCurrentDermatologist.postalCode || ""}
                                        labelVariant = "subtitle1"
                                        hasError = {false}
                                    />
                                    <ViewText
                                        label = "Ort"
                                        text = {localCurrentDermatologist.city || ""}
                                        labelVariant = "subtitle1"
                                        hasError = {false}
                                    />
                                    <ViewText
                                        label = "Praxisname"
                                        text = {localCurrentDermatologist.medicalOfficeName || ""}
                                        labelVariant = "subtitle1"
                                        hasError = {false}
                                    />
                                    <ViewText
                                        label = "KIM-Adresse"
                                        text = {localCurrentDermatologist.kimEmailAddress || ""}
                                        labelVariant = "subtitle1"
                                        hasError = {false}
                                    />
                                    <ViewText
                                        label = "Aktivierungsdatum"
                                        text = {localCurrentDermatologist.dateActivated ? new Date(localCurrentDermatologist.dateActivated).toLocaleString() : "Unbekannt"}
                                        labelVariant = "subtitle1"
                                        hasError = {false}
                                    />
                                    <ViewText
                                        label = "Sendedatum des Aktivierungscode"
                                        text = {localCurrentDermatologist.dateActivationKeySend ? new Date(localCurrentDermatologist.dateActivationKeySend).toLocaleString() : "Unbekannt"}
                                        labelVariant = "subtitle1"
                                        hasError = {false}
                                    />
                                    <ViewText
                                        label = "Aktiv"
                                        text = {localCurrentDermatologist.active ? "Ja" : "Nein"}
                                        labelVariant = "subtitle1"
                                        hasError = {false}
                                    />
                                </Box>
                        }
                    </Box>           
                    </form>
                </Box>
                <Box className="modal-footer">
                    <Button
                        variant="text"
                        color="primary"
                        onClick = {handleCancelClick}
                    >
                        {props.isEdit ? "Abbrechen" : "Schließen"}
                        
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick = {handleSaveClick}
                        disabled = {disabledSubmitButton()}
                    >
                        {props.isEdit ? "Speichern" : "Bearbeiten"}
                    </Button>
                    {!props.isEdit && localCurrentDermatologist.active &&
                        <Button
                            className = "margin-left-auto"
                            variant="contained"
                            color="primary"
                            onClick = {handleDeactivateClick}
                        >
                            Deaktivieren
                        </Button>
                    }
                  
                </Box>
                    <DeleteConfirmationDialog
                        isOpen = {isDeactivateDialogOpen}
                        hanldeCloseDialog = {handleCloseDeactivateDialog}
                        handleConfirmButton = {handleConfirmDeactivateDermatologist}
                        dialogTitle = "Dermatologe deaktivieren"
                        dialogContentText = {`Das Deaktivieren eines aktiven Dermatologen führt dazu, dass dieser Dermatologe nicht mehr im Dermatologenverzeichnis gezeigt wird.
                            Der Dermatologe wird per Mail informiert, dass sein Account deaktiviert wurde.
                            Der Dermatologe kann sich jederzeit wieder selbst aktivieren. Soll eine Reaktivierung verhindert werden, löschen Sie den Dermatologen.
                            Wollen Sie den Dermatologen deaktivieren?`}
                        confirmButtonText = "Deaktivieren"
                    />
            </Paper>
        </SimpleModal>
    )
}